<template>
  <div class="activity">
    <el-card style="width: 100%; border-radius: 12px; margin: 16px; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="发布状态:">
          <el-button
            v-for="item in el_button_demo"
            :key="item.value"
            :type="queryInfo.isShow == item.value ? 'primary' : ''"
            @click="activityStatusChange(item.value)"
            >{{ item.name }}</el-button
          >
        </el-form-item>
        <el-form-item label="发布时间：">
          <el-date-picker
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            v-model="queryInfo.createTime"
            type="datetime"
            placeholder="选择发布时间"
            @change="pickerChange"
          >
          </el-date-picker>
        </el-form-item>
        <!-- <span>商讯名称：</span> -->
        <!-- <el-input
          style="width: 284px"
          v-model="queryInfo.informationName"
          placeholder="请输入商讯名称，会员名称查询"
          clearable
        >
        </el-input>
        <el-button style="margin-left: 18px" @click="reset">重置</el-button>
        <el-button
          type="primary"
          @click="search"
          style="margin-left: 24px; background-color: #7c71e1"
          >搜索</el-button
        > -->
      </el-form>
    </el-card>
    <el-card style="margin: 16px; border-radius: 12px">
      <div class="add_search">
        <div class="add">
          <el-button
            type="primary"
            style="margin-bottom: 16px; background-color: #7c71e1"
            @click="handleAdd"
            >+ 新增商讯</el-button
          >
        </div>
        <div class="search">
          <el-input
            style="width: 382px"
            v-model="queryInfo.informationName"
            placeholder="请输入商讯名称，会员名称查询"
            clearable
          >
          </el-input>
          <!-- <el-button style="margin-left: 18px" @click="reset">重置</el-button> -->
          <el-button
            type="primary"
            @click="search"
            style="margin-left: 24px; background-color: #7c71e1"
            >搜索</el-button
          >
        </div>
      </div>

      <el-table
        :data="tableList"
        style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }"
        max-height="650"
        :header-cell-style="cellStyle"
      >
        <el-table-column type="index" label="编号" sortable align="center" width="150" />
        <el-table-column prop="coverImage" label="封面" width="160" align="center">
          <template v-slot="{ row }">
            <el-popover placement="bottom-start" trigger="hover">
              <el-image style="width: 200px" :src="row.coverImage" />
              <img
                slot="reference"
                :src="row.coverImage"
                style="max-width: 80px; max-height: 100px"
              />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="informationName" label="商讯名称" align="center"> </el-table-column>
        <el-table-column prop="tags" label="关联会员" align="center">
          <template slot-scope="scope">
            {{ scope.row.userName }}
          </template>
        </el-table-column>
        <el-table-column prop="tags" label="会员等级" align="center">
          <template slot-scope="scope">
            {{ scope.row.userName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="showTime"
          :default-sort="{ prop: 'createTime', order: 'descending' }"
          label="发布时间"
          sortable
          width="180"
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column prop="informationResource" label="资讯来源" align="center">
        </el-table-column> -->
        <el-table-column prop="tags" label="标签" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.tag === ''"></div>
            <div v-else>
              <el-tag type="primary" disable-transitions class="tag-style">
                {{ scope.row.tag }}</el-tag
              >
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="informationType" label="资讯类型" align="center" width="100">
        </el-table-column> -->
        <!-- <el-table-column prop="informationDescribe" label="资讯描述" width="350" align="center">
          <template v-slot="{ row }">
            <textFlow :info="row.informationDescribe" :lineClamp="2" :hiddenBtn="true"></textFlow>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="180" align="center">
          <template v-slot="{ row }">
            <el-button
              @click="clickIssue(row.id)"
              size="mini"
              type="text"
              class="color"
              v-if="row.isShow == 0"
              >发布</el-button
            >
            <el-button  @click="clickIssue(row.id)" size="mini" type="text" class="no_color" v-if="row.isShow == 1"
              >取消发布</el-button
            >

            <el-button class="compile" size="mini" type="text" @click="handleEdit(row.id)"
              >编辑</el-button
            >
            <el-button size="mini" style="color: #ff2828" type="text" @click="handleDelete(row.id)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.current"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-card>
  </div>
</template>
<script>
// import textFlow from '../../../components/textflow.vue'
import { informationList, deleteList, show } from '../../../api/information'
export default {
  name: 'activityManagement',
  components: {},
  data() {
    return {
      value: '',
      total: 0,

      cellStyle: {
        background: '#F6F6F6',
        color: '#333333'
      },
      tableList: [],
      queryInfo: {
        current: 1,
        informationName: '',
        isShow: null,
        createTime: '',
        size: 10
      },
      el_button_demo: [
        { name: '全部', value: null },
        { name: '已发布', value: 1 },
        { name: '未发布', value: 0 }
      ]
    }
  },
  created() {
    this.search()
    // console.log(this.queryInfo)
  },
  methods: {
    handleChange() {},
    //重置
    // reset() {
    //   this.queryInfo.informationType = ''
    //   this.queryInfo.informationName = ''
    //   this.queryInfo.createTime = ''
    //   this.search()
    // },
    //搜索
    async search() {
      const { data: res } = await informationList(this.queryInfo)
      if (res.resultCode === 200) {
        this.tableList = res.data.list
        this.total = res.data.total
      }
    },
    //资讯状态
    activityStatusChange(status) {
      //修改接口参数里面的状态值
      this.queryInfo.isShow = status
      //调用搜索方法搜索
      this.search()
    },
    //新增
    handleAdd() {
      this.$router.push({
        name: 'informationList',
        query: { informationType: 0 }
      })
    },
    //修改
    handleEdit(id) {
      this.$router.push({
        name: 'informationList',
        query: { informationType: 1, id: id }
      })
    },
    //删除
    async handleDelete(id) {
      const { data: res } = await deleteList({ id })
      if (res.resultCode === 200) {
        // console.log(res)
        this.$message.success(`资讯删除成功`)
        this.search()
      } else {
        this.$message.error('删除失败，请重试。')
      }
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.queryInfo.size = val
      this.search()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.queryInfo.current = val
      this.search()
    },
    // 时间选择
    pickerChange() {
      this.search()
      // console.log(this.queryInfo.createTime)
    },
    // 点击发布
    async clickIssue(id) {
      // console.log(id)
      const { data: res } = await show({ id: id })
      if (res.resultCode == 200) {
        this.search()
        this.$message.success('操作成功')
      }
      // console.log(res)
    }
  }
}
</script>
<style lang="less" scoped>
.activity {
  width: 100%;
  // overflow: auto;

  .new_page {
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }

  .tag-style {
    background-color: #f1f0fe;
    color: #7067f2;
    border-color: #ffffff;
  }
}
.color {
  color: #7c71e1;
}
.no_color {
  color: #727272;
}
.add_search {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.compile {
  padding-left: 12px;
  border-left: 2px solid #eeecec;
}
</style>
